.profile {
    margin: 40px auto 0;
    padding: 0;
    max-width: 880px;
    display: grid;
    grid-template-columns: 1fr minmax(336px, 4fr) 1fr;
    grid-template-rows: auto;
    column-gap: 30px;
    align-content: center;
}

@media screen and (max-width: 939px) {
    .profile {
        max-width: 88%;
    }
}

@media screen and (max-width: 699px) {
    .profile {
        justify-items: center;
        grid-template-columns: auto;
    }
}
