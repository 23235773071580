.header {
  margin: 45px auto 0;
  padding: 0;
  height: auto;
  max-width: 880px;
  border-bottom: 1px solid rgba(84, 84, 84, 0.7);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media screen and (max-width: 939px) {
  .header {
    max-width: 88%;
  }
}

@media screen and (max-width: 575px) {
  .header {
    max-width: 100%;
    margin-top: 28px;
    padding-right: 30px;
    display: grid;
    grid-template-columns: auto min-content;
    transition: margin-top 0.5s;
  }
}
