.element__likes {
    margin: 0;
    margin-bottom: 22px;
    margin-right: 26px;
    padding: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    min-width: 22px;
    text-align: center;
    color: #000000;
}