.profile-info__name {
    margin: 0;
    padding: 0;
    font-size: 42px;
    line-height: 114%;
    font-weight: 500;
    font-style: normal;
    max-width: 294px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@media screen and (max-width: 939px) {
    .profile-info__name {
        font-size: 34px;
        line-height: 122%;
        max-width: 250px;
    }
}

@media screen and (max-width: 699px) {
    .profile-info__name {
        font-size: 30px;
        margin-left: 46px;
        text-align: center;
        max-width: 220px;
        align-self: flex-end;
    }
}

@media screen and (max-width: 424px) {
    .profile-info__name {
        font-size: 27px;
        max-width: 189px;
    }
}