.profile__edit-button {
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    width: 120px;
    height: 120px;
    cursor: pointer;
    position: relative;
    z-index: 0;
}