.header__email {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 122%;
  text-align: right;
  color: #ffffff;
}

@media screen and (max-width: 575px) {
  .header__email {
    text-align: center;
    margin-top: 40px;
  }
}
