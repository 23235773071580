.auth-form__link {
  padding: 0;
  margin: 15px auto 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 121%;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}
