.header__navbar {
  margin: 0;
  margin-top: 6px;
  padding: 0;
  display: flex;
  flex-direction: row;
  column-gap: 24px;
  list-style: none;
}

@media screen and (max-width: 575px) {
  .header__navbar {
    margin: 0;
    padding: 40px auto 40px auto;
    flex-direction: column;
    align-content: center;
    width: 100%;
    height: 142px;
    background: black;
    text-align: center;
    position: fixed;
    row-gap: 18px;
    border-bottom: 1px solid rgba(84, 84, 84, 0.7);
    float: top;
    top: -144px;
    left: 0;
    transition: top 0.5s;
  }
}
