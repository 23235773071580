.tooltip__title {
  margin: 0 auto 60px;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 121%;
  text-align: center;
  max-width: 358px;
}

@media screen and (max-width: 499px) {
  .tooltip__title {
    font-size: 20px;
    line-height: 120%;
    max-width: 244px;
  }
}
