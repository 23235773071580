.element {
    margin: 0;
    padding: 0;
    border-radius: 10px;
    border: none;
    background-color: white;
    color: black;
    max-width: 282px;
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-template-rows: 1fr min-content max-content;
    column-gap: 21px;
    position: relative;
}