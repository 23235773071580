.popup__container {
    margin: 0 auto;
    padding: 0;
    background: #FFFFFF;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    min-width: 430px;
    color: black;
    position: relative;
}

@media screen and (max-width: 499px) {
    .popup__container {
        min-width: 282px;
    }
}