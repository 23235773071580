.auth-form__heading {
  margin: 0 auto;
  padding: 0;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 121%;
  width: 83%;
  text-align: center;
}

@media screen and (max-width: 499px) {
  .auth-form__heading {
    margin: 25px 22px 0;
    width: 84%;
    font-size: 20px;
    line-height: 120%;
  }
}
