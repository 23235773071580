.main__heading {
    margin: 30px auto 0;
    padding: 0;
    max-width: 80%;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    color: white;
}