.elements {
    margin: 0 auto;
    padding: 0;
    margin-top: 50px;
    max-width: 880px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(282px, 1fr));
    grid-template-rows: 1fr;
    grid-auto-rows: 1fr;
    row-gap: 20px;
    column-gap: 17px;
    justify-items: center;
    list-style: none;
}

@media screen and (max-width: 939px) {
    .elements {
        max-width: 88%;
    }
}

@media screen and (max-width: 699px) {
    .elements {
        margin-top: 36px;
    }
}