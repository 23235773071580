.element__title {
    margin: 25px 0 0 21px;
    padding: 0;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 120%;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    grid-row: span 2;
}