.auth-form {
  margin: 60px auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  max-width: 358px;
}

@media screen and (max-width: 499px) {
  .auth-form {
    max-width: 260px;
  }
}
