.element__delete-button {
    margin: 0;
    padding: 0;
    background: url(../../../images/elements-delete.svg);
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 18px;
    height: 19px;
    transition: opacity 0.3s linear;
}

.element__delete-button:hover {
    opacity: 60%;
}