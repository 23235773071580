.auth-form__input {
  margin: 50px auto 30px;
  padding: 0;
  width: 100%;
  border: none;
  background: none;
  border-bottom: 2px solid #cccccc;
  padding-bottom: 13px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 121%;
  color: white;
}
.auth-form__input:last-of-type {
  margin-top: 30px;
  margin-bottom: 216px;
}

@media screen and (max-width: 499px) {
  .auth-form__input:last-of-type {
    margin-bottom: 173px;
  }
}
