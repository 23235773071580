.popup {
    margin: 0 auto;
    padding: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.5);
    display: flex;
    visibility: hidden;
    opacity: 0;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    transition: visibility 0.3s, opacity 0.3s linear;
}