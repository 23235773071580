.popup__close {
    margin: 0;
    padding: 0;
    width: 32px;
    height: 32px;
    background: url(../../../images/popup-close.svg);
    background-size: 100%;
    border: none;
    cursor: pointer;
    position: absolute;
    top: -40px;
    right: -40px;
}

@media screen and (max-width: 499px) {
    .popup__close {
        width: 20px;
        height: 20px;
        top: -36px;
        right: 0;        
    }
}